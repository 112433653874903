<template>
  <v-app class="bg-login" id="auth">
    <loading :loading="isLoading" />
    <v-main>
      <v-container class="login-container" fluid>
        <v-row align-content="center" class="login-container__row">
          <v-col
            class="login-container__col hidden-sm-and-down"
            :class="handlePublication() ? 'left__publication' : 'left__av'"
            :style="!isLoading ? background : ''"
            cols="12"
            md="6"
          >
            <div v-if="handlePublication()" class="publication__text">
              <div v-html="publication.content" />
            </div>
            <div v-else class="left__text">
              <p v-if="!broker.id && !isLoading" class="uppercase">{{ $t('login_web_portal') }}</p>
            </div>
          </v-col>
          <!-- FORM LOGIN -->
          <v-col
            v-if="!isLoading"
            class="login-container__col right"
            cols="12"
            md="6"
          >
            <v-row>
              <v-col cols="8" offset="2">
                <div
                  class="logo-container d-flex flex-column justify-center align-center"
                >
                  <img
                    :src="logo"
                    :alt="altLogo"
                    :class="{'logo-container__image': !broker.id}"
                  />
                  <p class="logo-container__text">
                    {{ $t('login_welcome') }}
                  </p>
                </div>
              </v-col>
              <v-col cols="10" offset="1">
                <ValidationObserver v-slot="{ invalid }">
                  <form @submit.prevent="handleSubmit">
                    <v-row>
                      <v-col cols="12">
                        <!--EMAIL -->
                        <ValidationProvider
                          :name="$t('login_email')"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            id="email"
                            dense
                            :label="$t('login_email')"
                            v-model="userData.email"
                            :error-messages="errors[0]"
                            autocapitalize="none"
                            autofocus
                            :placeholder="$t('login_email')"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <!-- PASSWORD -->
                        <ValidationProvider
                          :name="$t('login_password')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            id="password"
                            v-model="userData.password"
                            :label="$t('login_password')"
                            :placeholder="$t('login_password')"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :error-messages="errors[0]"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" v-if="recaptchaShow">
                        <v-row justify="center">
                          <vue-recaptcha
                            v-if="recaptchaShow"
                            sitekey="Your key here"
                            loadRecaptchaScript
                          ></vue-recaptcha>
                        </v-row>
                      </v-col>

                      <v-col
                        cols="12"
                        class="d-flex justify-space-between flex-row align-baseline"
                      >
                        <div>
                          <!--
                          <v-checkbox
                            id="remember"
                            v-model="userData.remember"
                            :label="$t('login_remember_me')"
                            class="pt-8"
                          />
                          -->
                        </div>
                        <div>
                          <language-menu class="mx-auto" />
                        </div>
                      </v-col>
                      <v-col class="d-flex justify-end" cols="12">
                        <!-- <router-link
                          class="text-left"
                          :to="{ name: 'ForgotUser' }"
                          v-text="$t('login_forgot_user')"
                        /> -->
                        <router-link
                          class="text-left forgot-password-link"
                          :to="{ name: 'ForgotPassword', query: { broker: $route.query.broker } }"
                          >{{ $t('login_forgot_password') }}</router-link
                        >
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          color="primary"
                          type="submit"
                          :class="{
                            'login-button': !broker.id
                          }"
                          rounded
                          :loading="btnLoading"
                          :disabled="invalid || btnLoading"
                          >{{ $t('login_login') }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
                <v-divider class="my-5"></v-divider>
                <v-row v-if="!broker.id" class="mt-8" dense>
                  <v-col cols="6" md="6">
                    <v-row justify="center">
                      <img
                        src="../../assets/AV_005_avlogin.svg"
                        alt="Logo Av trading"
                        class="logo-container__image"
                        width="155px"
                        height="35px"
                      />
                    </v-row>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-row justify="center">
                      <img
                        src="../../assets/AV_006_avlogin.svg"
                        alt="Logo Av alternative"
                        class="logo-container__image"
                        width="155px"
                        height="35px"
                      />
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import PublicSettingService from '@/services/publicSetting'
import LanguageService from '@/services/language'
import BrokerService from '@/services/broker'
import Loading from '@/components/commons/Loading'
import LanguageMenu from '@/components/commons/LanguageMenu'
import { setVeeLocale } from '@/utils'
import AccountService from '@/services/account'
import TwoFactorModal from '@/components/commons/TwoFactorModal.vue'

export default {
  components: { VueRecaptcha, Loading, LanguageMenu },
  data() {
    return {
      isLoading: false,
      btnLoading: false,
      languages: [],
      userData: {
        email: '',
        password: '',
        remember: false
      },
      showPassword: false,
      recaptchaShow: false,
      maintenance: [],
      publication: {
        date: null,
        content: null
      },
      broker: {},
      publicSettings: [],
      whiteBranding: null
    }
  },
  computed: {
    ...mapState('language', ['idiom']),
    ...mapState(['loading']),
    ...mapState('auth', ['user', 'tempUser']),
    ...mapState('account', ['accounts']),

    currentDate() {
      return this.$date().format('YYYY-MM-DD')
    },

    logo() {
      return this.broker.id ? `data:image/png;base64,${this.broker.img_desktop}` : require('@/assets/logo_av_securities.svg')
    },

    altLogo() {
      return this.broker.id ? `${this.broker.name} Logo` : 'AV Agil Logo'
    },

    background() {
      return this.broker.id && this.broker.img_background ? `background-image: url('${this.broker.img_background}')` : `background-image: url('${require('@/assets/AV_001_inicio_sinletras.jpg')}')`
    }
  },
  watch: {
    idiom(newVal) {
      const content = this.publicSettings.find(
        item => item.key == `publications_content_${newVal.code}`
        )?.value
      this.$set(this.publication, 'content', content ?? null) 
    }
  },
  async created() {
    this.isLoading = true
    try {
      const queryLang = this.$route.query.lang
      await this.populateLanguages(queryLang)
      await this.getPublicSettings()

      const broker = this.$route.query.broker
      if (broker && this.whiteBranding) {
        await this.getBrokerInfo(broker)
        if (!this.broker.id) {
          const url = '/login'
          return window.location.replace(url)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions('auth', ['signIn','setCurrentUser', 'setTempUser']),
    ...mapActions('language', ['setLanguages', 'setLanguage', 'setIdiom']),
    ...mapActions('setting', ['setMaintenance', 'setSettings', 'setWhiteBranding']),
    ...mapActions('account', ['setAccounts', 'setAccount', 'setAuthorityAccounts']),

    async handleSubmit() {
      this.btnLoading = true
      try {
        await this.signIn(this.userData)
        
        let twoFactor = this.publicSettings.find(
          item => item.key === '2fa_portal_access'
        )
        if (twoFactor.value && this.tempUser.twofactor_habilitated) {
          let data = await this.handleTwoFactorModal(true, false)
          if (data && data == 'reset') {
            data = await this.handleTwoFactorModal(false, true)
          }
          if (data) {
            await this.setUserData()
            return
          }
        }

        if (this.tempUser.broker?.code !== this.$route.query.broker && this.whiteBranding) {
          console.log('windows.location')
          const brokerCode = this.tempUser.broker ? `?broker=${this.tempUser.broker.code}` : ''
          const url = `/preguntas-de-seguridad${brokerCode}`
          return window.location.replace(url)
        }

        this.$router.push({
          name: 'SecureQuestions',
          params: { id: this.tempUser.id },
          query: { broker: this.$route.query.broker }
        })
      } catch (e) {
        this.$dialog.notify.error(e)
      } finally {
        this.btnLoading = false
      }
    },
    async getPublicSettings() {
      const { data } = await PublicSettingService.fetch({})
      this.maintenance = data.find(item => item.key == 'maintenance')?.value
      this.publication.date = data.find(item => item.key == 'publications_date')?.value
      this.publication.content = data.find(item => item.key == `publications_content_${this.idiom.code}`)?.value
      this.whiteBranding = data.find(item => item.key == 'white_branding')?.value

      this.setMaintenance(this.maintenance)
      this.setSettings(data)

      this.publicSettings = data
    },

    async populateLanguages(queryLang) {
      const params = {
        limit: 999,
        'filter[active]': 1,
        'fields[languages]': 'id,code,name',
        include: 'translations'
      }
      const { data } = await LanguageService.fetch(params)
      const languages = data.data.map(item => {
        let messages = {}
        item.translations.forEach(item => {
          messages[item.label] = item.translation
        })
        return { ...item, translations: messages }
      })

      this.setLanguages(languages)

      // Broswer Language
      let lang = navigator.language.substr(0, 2)
      let defaultLang = languages.find(item => item.code === lang)

      if (queryLang) {
        defaultLang = languages.find(item => item.code === queryLang)
        lang = queryLang
      }

      if (!defaultLang) {
        defaultLang = languages.find(item => item.code === 'es')
        lang = 'es'
      }

      // Set Browser languages
      this.setIdiom(defaultLang)
      setVeeLocale(lang)
    },

    handlePublication() {
      return this.handlePublicationDate() 
        && this.handlePublicationContent() 
        && !this.broker.id 
        && !this.isLoading
    },

    handlePublicationDate() {
      return (this.publication.date !== '' && this.publication.date !== null) 
        && this.publication.date >= this.currentDate 
    },

    handlePublicationContent() {
      return this.publication.content !== null && this.publication.content !== ''
    },
    
    async getBrokerInfo(code) {
      this.isLoading = true
      try {
        const { data} = await BrokerService.getBrokerInfo(code)
        this.broker = data
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false
      }
    },

    async handleTwoFactorModal(twofactor, reset) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: reset ? '80%' : '40%',
        transition: 'dialog-top-transition',
        persistent: false,
        twoFactor: twofactor,
        hideCancel: true,
        hideSubmit: true,
        hideOverlay: false,
        hideTitle: true,
        reset: reset,
        showClose: twofactor
      }
      const data = await this.$dialog.showAndWait(TwoFactorModal, params)
      return data
    },

    async setUserData() {
      this.setCurrentUser(this.tempUser)
      this.setTempUser(null)

      this.setWhiteBranding(this.whiteBranding)

      await this.populateAccounts()

      /** Con $router.push no ejecuta el created() de App.vue, por lo tanto no refresca 
       *  los estilos del broker luego del login. Para solucionar esto se utilizó un
       *  windows.location ya que vuelve a ejecutar el created() de App.vue y aplica los colores.
       */
      //const routeName = this.accounts.length > 1 ? 'AccountSelect' : 'Home'
      //this.$router.push({ name: routeName })
      const routeName = this.accounts.length > 1 ? '/seleccion-cuenta' : '/'
      return window.location.replace(routeName)
    },

    async populateAccounts() {
      this.setAuthorityAccounts([])
      let accounts = []
      let unavailableAccounts = []
      let params = {}

      let account = this.user.avobjects.find(
        obj => obj.typeCode === 'accountCode'
      )
      let authority = this.user.avobjects.find(
        obj => obj.typeCode === 'authorityCode'
      )
      let group = this.user.avobjects.find(obj => obj.typeCode === 'groupCode')

      if (account) {
        params['accountCode'] = account?.code
      }

      if (group) {
        params['groupCode'] = group?.code
      }

      if (account || group) {
        const { data } = await AccountService.fetch(params)
        accounts = data
      }

      this.setAccounts(accounts)
      this.setAccount(null)

      if (authority) {
        const authorityAccounts = await AccountService.fetch({
          authorityCode: authority?.code
        })

        unavailableAccounts = authorityAccounts.data.filter(function(array_el) {
          return (
            accounts.filter(function(anotherOne_el) {
              return anotherOne_el.code == array_el.code
            }).length == 0
          )
        })

        this.setAuthorityAccounts(unavailableAccounts)
        this.setAccounts(accounts.concat(unavailableAccounts))
      }

      if (accounts.length === 1 && unavailableAccounts.length === 0) {
        this.setAccount(accounts[0])
      }
    }
  }
}
</script>

<style>
#auth label.v-label {
  transform: translateY(-18px) scale(.75);
}
</style>
<style scoped>
.theme--light.v-application.bg-login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background: rgb(255, 255, 255);
  background-size: cover !important;
  background-repeat: no-repeat;
  /* font-family: Lato; */
}
.login-container {
  min-height: 100vh;
}
.login-container__row {
  min-height: 100vh;
}
.login-container__col {
  min-height: 100vh;
}
.left__av {
  background-size: cover;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 100vh;
}

.left__publication {
  background-image: url('../../assets/AV_004_inicio_portal_clientes_sinletra.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 50px;
}
.left__text {
  width: 50px;
  margin-left: -200px;
  text-align: left;
  font: normal normal 300 44px/52px Lato !important;
  letter-spacing: -1.1px !important;
  color: #0294de !important;
  opacity: 1;
}

.publication__text {
  color: white;
  width: 100vh;
}

.right {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.form {
  display: flex;
  flex-direction: column;
}
.links {
  display: flex;
  justify-content: space-between;
}
.links a {
  font: normal normal normal 16px/27px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
  text-decoration: none;
}
.login-button {
  min-width: 140px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.btn-cancel {
  min-width: 140px !important;
}
.logo-container__text {
  margin-top: 20px;
  text-align: center;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}
.logo-container__image {
  width: 85%;
}
.bg-login {
  font: normal normal normal 16px/27px Lato;
}
.form label {
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0px;
}

.uppercase {
  text-transform: uppercase;
}

.forgot-password-link,
.forgot-password-link:visited {
  color: var(--v-primary-base) !important;
}

@media (max-width: 979px) {
  
  .logo-container__text {
    font: normal normal normal 16px/18px Lato;
  }
  .logo-container__text_two {
    font: normal normal normal 16px/18px Lato;
  }
  .text-left {
    font: normal normal normal 12px/27px Lato;
  }
}
</style>